import serviceAxios from "./index";


export const getUserInfo = (params) => {
  return serviceAxios({
    url: "api/v1/index/chart?category=smgs&platform=0&type=DAY",
    method: "get",
    params,
  });
};
export const login = (data) => {
  return serviceAxios({
    url: "/api/user/login",
    method: "post",
    data,
  });
};
export const getQunById = (params) => {
  return serviceAxios({
    url: "/h5/getQunById",
    method: "get",
    params,
  });
};
export const uploadsImg = (data,headers) => {
  console.log(data,headers)
  return serviceAxios({
    url: "/uploads",
    method: "post",
    data,
    headers
  });
};