<template>
  <div class="home">
    <div class="bgBox">
      <div class="c_name">{{info.name}}</div>
      <div class="classOrderName">{{ qname }}</div>
      <div class="qrcode">
        <img :src="qunurl" class="qrcodeimg" alt="">
      </div>
      <div class="u_desc">微信内扫码或长按二维码加群</div>
    </div>
  </div>
</template>

<script>
 import { getQunById} from "../api/home";
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
    
  },
  
    data(){
      return{
      list:[
        {
        name:'少林内功班',id:'a01slng',qrcode:require('../assets/SLNG.jpg') 
      }
    ],
      info:{

      },
      qname:'',
      id:null,
      qunurl:''
    }
  },
  mounted(){
  //  this.getList()
  let id=this.$route.query.id
  this.id=id
  console.log(id)
  this.list.forEach(ele=>{
    if(ele.id==id){
      this.info=ele
    }
  })
  this.getList()
  },
  methods:{
    getList(){
      
      getQunById({id:this.id}).then(res=>{
        this.mydata=res.data
        this.qunurl=res.data.qrcode
        this.qname=res.data.qname
        console.log(this.mydata)
      })
    }
  }
}
</script>
<style scoped  >
.home{
  background-color: antiquewhite;
  width: 100vw;height: calc(100vh - 20px);padding-top: 20px;overflow: hidden;
}
.bgBox{
   background: url('../assets/bg.png');
   width: 375px;height:673px;background-size: 100%;background-repeat: no-repeat;
   margin: 0 auto;
   position: relative;
}
.classOrderName{
  position: absolute;top: 263px;margin-left: calc(50% - 100px);width: 200px;overflow: hidden;
}
.c_name{
  position: absolute;top: 262px;margin-left: calc(50% - 100px);width: 200px;font-size: 18px;font-weight: bold;
  letter-spacing: 3px;
}
.u_desc{
  position: absolute;top: 512px;margin-left: calc(50% - 100px);width: 200px;font-size: 15px;font-weight: bold;
}
.qrcode{
   width: 200px;position: absolute;
   height: 200px;top: 310px;margin-left: calc(50% - 100px);
}
.qrcodeimg{
  width: 100%;
  height: 100%;background-size: 100%;
}

</style>
<style>
body{
  margin: 0;
}
</style>
